<template>
  <div class="description">
    <h1>{{ $t(title) }}</h1>
    <div class="text" v-bar>
      <div>
        <div style="width: 95%;">
          <div @mouseover="mouseover" @mouseleave="mouseleave" v-html="$t(description)"></div>
        </div>
      </div>
    </div>
    <h6 style="width: 93%;">{{ $t(name) }}</h6>
    <app-button />
  </div>
</template>
<script>
import Button from '@/components/Cases/Button'
export default {
  name: 'FormBox',
  components: {
    'app-button': Button
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    name: {
      type: String
    }
  },
  methods: {
    mouseover () {
      this.$emit('mouseover')
    },
    mouseleave () {
      this.$emit('mouseleave')
    }
  }
}
</script>
<style scoped>
@media only screen and (max-width: 560px) {
  .texto {
    font-size: 10px;
  }
}

.description {
  text-indent: 0;
  font-size: 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  padding: 15px 0 0 15px;
  color: #3C3C3C;
  text-align: justify;
  width: 100%;
  height: 100%;
  flex:1;
}
.description * {
  font-family: "gilroy", sans-serif;
}
.description h1 {
  color: #DC1C2E;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 15px;
  display: inline-block;
  text-align:start;
  font-family: "gotham-black", sans-serif;
}
.text {
  line-height: 1.2em;
  margin-bottom: 10px;
  font-size: 1rem;
  height: 10em;
  overflow: hidden;
  overflow-y: scroll;
  color: #3e3e3ede;
}
.description h6 {
  line-height: 1.5em;
  margin-bottom: 10px;
  max-width: 450px;
  font-size: 0.6rem;
  font-weight: bolder;
  font-family: "gilroy", sans-serif;
}
@media (min-width: 768px) {
  .text  {
    font-size: 0.89rem;
    font-weight: lighter;
    max-width: 98%;
  }

  .description h1 {
    font-size: 1.5em;
  }

  .description h6 {
    max-width: 98%;
    font-size: 0.8rem;
    text-align: right;
    font-weight: bold;
  }
  .h1 {
    font-size: 63px;
  }
}

@media(min-width: 1600px) {
  .text {
    height: 15em;
    font-size: 1.06rem;
  }
}

@media(min-width: 1900px) {
  .text {
    height: 18em;
  }
}
</style>
<style>
.text p{
  margin-bottom: 10px;
}
</style>
