<template>
    <div class="container-case">
      <div class="photo-case">
        <app-image :src="image" />
      </div>
      <div class="content-case">
        <app-description @mouseover="hover" @mouseleave="mouseleave" :title="title" :description="description" :name="name" />
      </div>
    </div>
</template>

<script>
import Image from '@/components/Cases/Image'
import FormBox from '@/components/Cases/Description'

export default {
  names: 'Cases',
  components: {
    'app-description': FormBox,
    'app-image': Image
  },
  props: {
    image: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    hover () {
      this.$emit('hover')
    },
    mouseleave () {
      this.$emit('mouseleave')
    }
  }
}
</script>
<style>
body {
  background-color: #fafafa;
  color: #050505 !important;
}
* {
  box-sizing: border-box !important;
}
</style>
<style scoped>
.container-case {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-image: url('../../assets/pattern-people.svg');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position-x: right;
}
.content-case {
  min-height: 39vh;
}
.content-case,
.photo-case {
  width: 100%;
}

.container-case {
  flex-direction: column;
  align-content: flex-start;
  width: 90vw;
  margin: auto;
  padding: 22px;
  box-shadow: 0px 5px 8px 1.5px rgba(105, 105, 105, 0.685);
}
@media only screen and (min-width: 960px) {
  .container-case {
    flex-direction: row;
    width: 70vw;
    margin-top: 50px;
    box-shadow: 0px 5px 8px 1.5px rgba(200, 200, 200, 0.685);
  }
  .photo-case {
   flex-basis: 50%;
   height: 50vh;
  }
  .content-case {
    display: flex;
    flex-basis: 50%;
    min-height: 100%
  }
}

</style>
