export default [
  {
    TextTitle: 'people5.title',
    TextDecription: 'people5.decription',
    Textname: 'people5.name',
    image: {
      src: require('@/assets/Pessoas/7..jpg'),
      alt: 'People'
    }
  },
  {
    TextTitle: 'people4.title',
    TextDecription: 'people4.decription',
    Textname: 'people4.name',
    image: {
      src: require('@/assets/Pessoas/2..jpg'),
      alt: 'People'
    }
  },
  {
    TextTitle: 'people6.title',
    TextDecription: 'people6.decription',
    Textname: 'people6.name',
    image: {
      src: require('@/assets/Pessoas/6.jpg'),
      alt: 'People'
    }
  },
  {
    TextTitle: 'people7.title',
    TextDecription: 'people7.decription',
    Textname: 'people7.name',
    image: {
      src: require('@/assets/Pessoas/1..jpg'),
      alt: 'People'
    }
  },
  {
    TextTitle: 'people1.title',
    TextDecription: 'people1.decription',
    Textname: 'people1.name',
    image: {
      src: require('@/assets/Pessoas/5..jpg'),
      alt: 'People'
    }
  },
  {
    TextTitle: 'people3.title',
    TextDecription: 'people3.decription',
    Textname: 'people3.name',
    image: {
      src: require('@/assets/Pessoas/8..jpg'),
      alt: 'People'
    }
  },
  {
    TextTitle: 'people8.title',
    TextDecription: 'people8.decription',
    Textname: 'people8.name',
    image: {
      src: require('@/assets/Pessoas/4.jpg'),
      alt: 'People'
    }
  },
  {
    TextTitle: 'people2.title',
    TextDecription: 'people2.decription',
    Textname: 'people2.name',
    image: {
      src: require('@/assets/Pessoas/3..jpg'),
      alt: 'People'
    }
  }
]
