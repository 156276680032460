<template>
    <img :src="src" alt="foto"/>
</template>

<script>
export default {
  name: 'Image',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
}
</style>
