<template>
  <button
    class="Botton_css"
    @click="candidata"
    :data-description="$t('buttonCandidature')"
  >
    {{ $t("buttonCandidature") }}
  </button>
</template>
<script>
export default {
  methods: {
    candidata () {
      window.open('https://recrutamento.ucall.co.ao/', '_blank')
      /* this.$eventHub.$emit("show-modal"); */
    }
  }
}
</script>
<style scoped>
@media only screen and (min-width: 768px) {
  div {
    justify-content: center;
    /* margin-top: 80px; */
  }

  .Botton_css {
    margin: 0 auto;
  }
}
.Botton_css {
  color: #dc1c2e;
  background: #fff;
  border: 2px solid #dc1c2e;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  display: block;
  padding: 10px 25px;
  overflow: hidden;
  letter-spacing: 1.5px;
  border-radius: 20px;
}

.Botton_css::before {
  content: attr(data-description);
  position: absolute;
  height: 100%;
  width: 100%;
  background: #dc1c2e;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.26s linear;
  transform: translateY(-100%);
}

.Botton_css:hover::before {
  transform: translateY(0%);
  z-index: 1;
}
</style>
