<template>
  <!-- modal -->
  <transition name="">
    <div class="modal-people zoomIn" :class="{ 'show animated': isModalActive }">
      <div class="modal-body">
        <button @click="closeModal" class="btn_close">X</button>
        <div class="content-modal">
          <div class="texto" v-html="$t('modalWhatsApp.Text')">
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isModalActive: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    closeModal () {
      this.$eventHub.$emit('close-modal')
    }
  }
}
</script>

<style>
@media only screen and (max-width: 560px) {
  .modal-body {
    width: 150px;
  }
}
ul li {
  list-style: none;
}
.texto{
  text-align:left!important;
}
.texto ul{
  padding-inline: 20px;
  list-style: disc!important;
}
.texto ul li{
  list-style: disc!important;
}
.btn_close {
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  font-size: 20px;
  cursor: pointer;
  height: 40px;
  margin-left: 90%;
}
.content-modal {
  text-align: center;
  background-color: #ad313c;
  color: #fff;
  margin: 10px;
  padding: 15px 10px;
}
.modal-people.show {
  display: flex;
  z-index: 1000;
}
.modal-people {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.modal-body {
  background-color: #fff;
  width: 600px;
  padding: 10px;
}
</style>
