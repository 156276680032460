<template>
  <div class="case">
    <img src="@/assets/circle.png" alt="Circle" class="circle" />
    <swiper class="web" :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide v-for="(slide, index) in data" :key="index">
        <app-case :image="slide.image[0]" height="350" :title="slide.title" :description="slide.description"
          :name="slide.footer" @hover="disableMouseWhell" @mouseleave="enableMouseWhell" />
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <!-- mobile -->
    <div class="mobile">
      <div class="item-cases" v-for="(slide, index) in data" :key="index">
        <app-case :image="slide.image[0]" :title="slide.title" :description="slide.description" :name="slide.footer" />
      </div>
    </div>

    <!-- modal -->
    <ucall-modal-candidate :is-modal-active="isModalActive" />
    <!-- end modal -->
  </div>
</template>
<script>
import People from '@/utils/people.js'
import Modal from '@/components/Cases/modal'
import CaseComponent from '@/components/Cases/CaseComponent'
import axios from 'axios'
export default {
  name: 'App-Cases',
  metaInfo: {
    title: 'Pessoas - Ucall'
  },
  components: {
    'app-case': CaseComponent,
    'ucall-modal-candidate': Modal
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  watch: {
    '$i18n.locale': {
      handler: async function (after, before) {
        await this.get(after)
      },
      deep: true
    }
  },
  methods: {
    async get () {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/people`, {
          params: {
            $orderby: 'created desc',
            $limit: 8
          },
          headers: {
            'X-Resolve-Urls': 'logo,image',
            'X-Languages': this.$i18n.locale,
            'X-Flatten': true
          }
        })
        .then((resp) => {
          let data = []
          resp.data.items.forEach((element) => {
            data.push({ ...{ id: element.id }, ...element.data })
          })
          this.data = data
          /* console.log('RESULTADO PEOPLE', this.data) */
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.log(error)
          /* eslint-enable no-console */
        })
    },
    onShowModal () {
      this.isModalActive = !this.isModalActive
    },
    disableMouseWhell () {
      this.swiper.mousewheel.disable()
      this.swiper.update()
    },
    enableMouseWhell () {
      this.swiper.mousewheel.enable()
      this.swiper.update()
    }
  },
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        direction: 'horizontal',
        spaceBetween: 40,
        updateOnWindowResize: true,
        mousewheel: true,
        draggable: true,
        keyboard: true,
        centeredSlides: true
      },
      swiperSlides: People,
      isModalActive: false,
      data: []
    }
  },
  mounted () {
    this.$eventHub.$on('close-modal', this.onShowModal)
    this.$eventHub.$on('show-modal', this.onShowModal)
    this.get()
  },
  destroyed () {
    this.$eventHub.$off('close-modal')
    this.$eventHub.$off('show-modal')
  }
}
</script>
<style lang="css" scoped>
.circle {
  position: fixed;
  width: 250px;
  top: -160px;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
}

.case {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  overflow: hidden;
}

.web {
  display: none;
}

.mobile {
  display: block;
  margin-top: 5vh;
  height: 100vh;
  overflow-y: scroll;
}

.item-cases {
  margin: 10px 0;
  overflow: hidden;
}

@media (min-width: 960px) {
  .case {
    padding-top: 0;
    height: 100vh;
  }

  .web {
    display: block;
  }

  .mobile {
    display: none !important;
  }
}
</style>
